import { IonIcon } from '@ionic/react';
import { logoWhatsapp } from 'ionicons/icons';
import { WHATS_APP_PHONE_NUMBER } from '../../../../../../utils/phoneNumber';
import s from '../Form.module.scss';

type Props = {
  applyCustomStyles?: boolean;
  alignToCenter?: boolean;
  onClick?: () => void;
};

export const TextUsButton: React.FC<Props> = ({
  applyCustomStyles,
  alignToCenter,
  onClick,
}) => {
  return (
    <>
      <button className={s.whatsAppButton} type="submit" onClick={onClick}>
        <IonIcon icon={logoWhatsapp} style={{ fontSize: '24px' }} />
        Text us
      </button>

      <p
        style={{
          textAlign: alignToCenter ? 'center' : undefined,
          color: applyCustomStyles ? 'black' : 'rgba(255, 255, 255, 0.7)',
          marginTop: '15px',
          fontSize: '12px',
          ...(alignToCenter
            ? {
                marginLeft: '15px',
                marginRight: '15px',
              }
            : { marginLeft: '3px' }),
        }}
      >
        * Message will be send to Whats App: {WHATS_APP_PHONE_NUMBER}
      </p>
    </>
  );
};
